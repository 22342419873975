import React, {Component} from "react";
import logo from "../logo.svg";
import {connect} from "react-redux";
import {NavLink} from "react-router-dom";


class Footer extends Component {

    render () {
        return (
            <footer>
                    <div className="row text-center text-green pt-5 pb-5">
                        <div className="col-md-3 col-sm-12 smed">
                            <a title="facebook" target="#" href="https://www.facebook.com/"><i
                                className="fab fa-facebook"></i></a>
                            <a title="instagram" target="#" href="https://www.instagram.com/"><i
                                className="fab fa-instagram"></i></a>
                            <a title="youtube" target="#" href="https://www.youtube.com/"><i
                                className="fab fa-youtube"></i></a>
                        </div>
                        <div className="col-md-3 col-sm-12 info">
                            <div className="left mx-auto w-50">
                                <h4 className="pb-4">{this.props.langStore.footer.contact.heading}</h4>
                                <h5 className='mb-1'><span>{this.props.langStore.company_name}</span></h5>
                                <p><span>{this.props.langStore.footer.contact.country}, {this.props.langStore.footer.contact.town}</span></p>
                                <p><span>{this.props.langStore.footer.contact.street}</span></p>
                                <p><span>{this.props.langStore.footer.contact.phone}</span></p>
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-12 info">
                            <div className="left mx-auto w-50">
                                <h4 className="pb-4">{this.props.langStore.footer.about.heading}</h4>
                                <p>
                                    <NavLink to="about">
                                        {this.props.langStore.footer.about.about}
                                    </NavLink>

                                </p>
                                <p><NavLink to='faq'>{this.props.langStore.footer.about.faq}</NavLink></p>
                            </div>
                        </div>

                        <div className="col-md-3 col-sm-12">
                            <a className="" href={`${process.env.PUBLIC_URL}`}>
                                <img
                                    src={logo}
                                    width="100"
                                    height="100"
                                    className="d-inline-block align-top"
                                    alt="Zdravetc Logo"
                                />
                            </a>
                            <p className="copy">{this.props.langStore.copyright}</p>
                        </div>
                    </div>
            </footer>
        );
    }
}

const mapStateToProps = function(state, ownProps) {
    return {
        langStore: state,
        location: ownProps.location
    }
}

export default connect(mapStateToProps, null)(Footer);