import React, {Component} from 'react';
import Header from './components/Header';
import HeaderRouter from './components/HeaderRouter';
import Footer from './components/Footer';
import ScrollArrow from "./components/ScrollArrow";
import Loading from "./components/Loading";

class App extends Component {
    render() {
        return (
          <div className="container-fluid">
              <Header/>
              <HeaderRouter />
              <Footer />
              <ScrollArrow />
          </div>
        );
    }
}

export default App;