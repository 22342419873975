import React, {Component, Suspense} from "react";
import Carousel from "react-bootstrap/Carousel";
import ProductCardBootstrapped from "./ProductCardsBoostraped";
import Testimonials from "./Testimotionals";

//import CardGridImages from "./CardGridImages";
import {connect} from "react-redux";
/* wait 500 ms to render component */
const CardGridImages = React.lazy(
    () =>
        new Promise((resolve, reject) =>
            setTimeout(() => resolve(import("./CardGridImages")), 2500)
        )
);

class Home extends Component {


    render () {
        return (
            <section>
                <div>
                    <Carousel controls={false} indicators={false}>
                        <Carousel.Item id="slider-img-first" className='slider-img grn parallax noparallax'>
                            <Carousel.Caption className="mr-auto ml-auto">
                                <p className='zdravec-since'>
                                    <span>
                                        {this.props.langStore.home.heading.part_1}
                                    </span>
                                </p>
                                <div className='zdravec-slider-line'></div>
                                <h1>{this.props.langStore.home.heading.part_2}</h1>
                                <div className='zdravec-slider-line'></div>
                                <p className='zdravets-slogan'>
                                    <span>{this.props.langStore.home.heading.part_3}</span>
                                </p>
                            </Carousel.Caption>
                        </Carousel.Item>
                    </Carousel>
                </div>
                <div>
                    <ProductCardBootstrapped/>
                    <Testimonials/>
                    <Suspense fallback={<div>Loading</div>}>
                        <CardGridImages/>
                    </Suspense>
                </div>
            </section>
        )
    }
}
const mapStateToProps = function(state, ownProps) {
    return {
        langStore: state,
        location: ownProps.location
    }
}

export default connect(mapStateToProps, null)(Home);
