import React, {Component} from 'react';
import {connect} from "react-redux";
import bg_wheat from "../img/zdravec/zdravec-icons/zdravec_bot_small.png";
import Button from "react-bootstrap/Button";
import axios from 'axios';
import { API_PATH } from '../redux/constants';

class Contact extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name : '',
            phone : '',
            email : '',
            message : '',
            responseMessage: [],
            responseMessageClass : '',
            mailSent : '',
            formErrors : ''
        }
    }

     validateForm(name, email, message) {
        const errors = [];

        if (name.length === 0) {
            errors.push("Name can't be empty!");
        }

         if (message.length === 0) {
             errors.push("Message can't be empty!");
         }

        if (!this.validateEmail(email)) {
            errors.push("Please provide valid email address !");
        }

        return errors;
    }

     validateEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const result =  re.test(email);

        return result;
     }

    handleFormSubmit = e => {
        e.preventDefault();

        const errors = this.validateForm(this.state.name, this.state.email, this.state.message);

        if (errors.length > 0) {
            this.setState({
                responseMessage: errors,
                responseMessageClass: 'text-red'
            });
            return;
        }

        axios({
            method: 'post',
            url: API_PATH,
            headers: { 'content-type': 'application/json' },
            data: this.state
        })
            .then(result => {
                this.setState({
                    mailSent: result.data.sent,
                    responseMessage: result.data.responseMessage,
                    responseMessageClass: result.data.responseMessageClass,

                })
            })
            .catch(error => {
                this.setState({
                    mailSent: error.data.sent,
                    responseMessage: error.data.responseMessage,
                    responseMessageClass: error.data.responseMessageClass
                })
            });

    };


    render () {
        return (
            <section>
                <div id="contact">
                    <div className='contact-inner'>
                        <div className="container-fluid">
                            <div className="row justify-content-md-center text-green about-container">
                                <div className="col-lg-6 zdravec-content-text p-5">
                                    <h2 className="text-center">{this.props.langStore.contact.form.heading}</h2>

                                    <div className="container-fluid">
                                        <div className="row justify-content-md-center text-green">
                                            <div className="col-lg-6 contact-hours text-center">
                                                <div>+359 883 305 680</div>
                                            </div>
                                            <div className="col line line-small">

                                            </div>
                                            <div className="col-lg-6 contact-hours text-center">
                                                <div>tpk.zdravec@abv.bg</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="container-fluid w-100">
                                        <div className="row justify-content-md-center text-green">
                                            <div className="form__group field">
                                                <input type="input" className="form__field" placeholder="Name" name="name"
                                                       id='name' value={this.state.name}
                                                       onChange={e => this.setState({ name: e.target.value })} required/>
                                                <label htmlFor="name" className="form__label">{this.props.langStore.contact.form.client_name}</label>

                                            </div>
                                            <div className="form__group field ">
                                                <input type="input" className="form__field" placeholder="Phone" name="phone"
                                                       id='phone' value={this.state.phone}
                                                       onChange={e => this.setState({ phone: e.target.value })} required/>
                                                <label htmlFor="phone" className="form__label">{this.props.langStore.contact.form.client_phone}</label>
                                            </div>
                                        </div>
                                        <div className="row justify-content-md-center text-green w-100">
                                            <div className="form__group__large field">
                                                <input type="input" className="form__field" placeholder="Email" name="email"
                                                       id='email' value={this.state.email}
                                                       onChange={e => this.setState({ email: e.target.value })} required/>
                                                <label htmlFor="email" className="form__label">{this.props.langStore.contact.form.client_email}</label>

                                            </div>
                                        </div>

                                        <div className="row justify-content-md-center text-green w-100">
                                            <div className="form__group__large field">
                                                <textarea className="form__field" placeholder="Message" name="message"
                                                       id='message' value={this.state.message}
                                                          onChange={e => this.setState({ message: e.target.value })} required/>
                                                <label htmlFor="message" className="form__label">{this.props.langStore.contact.form.client_message}</label>

                                            </div>
                                        </div>
                                    </div>
                                    <div className={"text-center pt-3 " + this.state.responseMessageClass}>
                                        {this.state.responseMessage.map((msg, i) => {
                                            // Return the element. Also pass key
                                            return (<p key={i}>{msg}</p>)
                                        })}
                                    </div>

                                    <div className="text-center pt-3">
                                        <Button size="lg" className='zdravec-card-button  btn-color-grn' onClick={e => this.handleFormSubmit(e)}>
                                            {this.props.langStore.buttons.send}
                                        </Button>
                                    </div>
                                </div>
                                <div className="col-lg-6 zdravec-image-container contact-image-first">

                                </div>

                            </div>


                        </div>
                    </div>
                    <div className="container revs text-center text-green">
                        <div className="texts pb-5">
                            <img src={bg_wheat} className="img-responsive w-25" alt="contact"/>
                            <h1>{this.props.langStore.contact.work_hours.heading}</h1>
                            <div className="container-fluid contact-time-available">
                                <div className="row justify-content-md-center text-green">
                                    <div className="col-lg-6 contact-hours">
                                        <div>{this.props.langStore.contact.work_hours.address_part_1}</div>
                                        <div>{this.props.langStore.contact.work_hours.address_part_2}</div>
                                    </div>
                                    <div className="col line line-big">

                                    </div>
                                    <div className="col-lg-6 contact-hours">
                                        <div>{this.props.langStore.contact.work_hours.work_hours}</div>
                                        <div>{this.props.langStore.contact.work_hours.work_hours_2}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="contact-parallax-container">
                    <section className="contact-section section-parallax contact-image-second noparallax">

                    </section>
                </div>

                <section >
                    <div className="map">
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2897.800537837426!2d24.61573205169194!3d43.42299397902741!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40ab8b36028b0197%3A0x409027753276127d!2sul.%20%22Vit%22%2015%2C%205809%20Northern%20Industrial%20Zone!5e0!3m2!1sen!2sbg!4v1592897262325!5m2!1sen!2sbg"
                            width="100%" height="450" frameBorder="0" style={{border: 0}} allowFullScreen=""
                            aria-hidden="false"
                            tabIndex="0">
                        </iframe>
                    </div>
                </section>




            </section>
        );
    }
}

const mapStateToProps = function(state, ownProps) {
    return {
        langStore: state,
        location: ownProps.location
    }
}

export default connect(mapStateToProps, null)(Contact);