import React, {Component} from "react";
import bg_wheat from "../img/zdravec/zdravec-icons/zdravec_bot_small.png";
import Nav from "react-bootstrap/Nav";
import Button from "react-bootstrap/Button";
import {connect} from "react-redux";

class ProductBoza extends Component {


    render () {
        return (
            <section>
                <div id="about" className="pad">
                    <div className="container-fluid">
                        <div className="row pad justify-content-md-center text-green about-container">
                            <div className="col-lg-6 zdravec-boza-content-text orange pad">
                                <h2 className="text-center pt-5">{this.props.langStore.the_boza.beverage.heading}</h2>
                                <div className="col-md-auto">
                                    {this.props.langStore.the_boza.beverage.content}
                                </div>
                            </div>
                            <div className="col-lg-6 zdravec-image-container boza-image-first">

                            </div>
                        </div>
                    </div>

                    <div className="container revs text-center text-green">
                        <div className="texts pb-5">
                            <img src={bg_wheat} className="img-responsive w-75" alt="boza-park-sofia"/>
                            <h2>{this.props.langStore.the_boza.drink_boza_part_1.heading}</h2>
                            <div>
                                {this.props.langStore.the_boza.drink_boza_part_1.content}
                            </div>
                            <Button size="lg" className='zdravec-card-button btn-color-grn'>
                                <Nav.Link href={`${process.env.PUBLIC_URL}/contact`}>{this.props.langStore.buttons.visit}</Nav.Link>
                            </Button>
                        </div>
                    </div>


                    <div className="container-fluid">
                        <div className="row pad justify-content-md-center text-green about-container">
                            <div className="col-lg-6 zdravec-image-container boza-image-second">

                            </div>
                            <div className="col-lg-6 zdravec-boza-content-text orange pad">
                                <div className="col-md-auto">
                                    <h2>{this.props.langStore.the_boza.drink_boza_part_2.heading}</h2>
                                    <div className="col-md-auto">
                                        {this.props.langStore.the_boza.drink_boza_part_2.content}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

const mapStateToProps = function(state, ownProps) {
    return {
        langStore: state,
        location: ownProps.location
    }
}

export default connect(mapStateToProps, null)(ProductBoza);