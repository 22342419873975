import React, {Component}  from "react";

import {connect} from "react-redux";
import Tabs from 'react-bootstrap/Tabs';
import Tab from "react-bootstrap/Tab";
import Water from "./Water";
import Boza from "./Boza";
import Ayran from "./Ayran";

class Products extends Component {
    handleSelect(route) {
        // Get your route name from params and pass it to history.push()
        this.props.history.push(route);
    }

    render() {

        return (
            <div className="container-fluid pt-5" id="products-container">
                <div className="row">
                        <div className="tab" >
                            <Tabs onSelect={this.handleSelect.bind(this)} defaultActiveKey={process.env.PUBLIC_URL + this.props.history.location.pathname.replace(process.env.PUBLIC_URL,'')} id="uncontrolled-tab-example">
                                <Tab eventKey={`${process.env.PUBLIC_URL}/products/boza`} title={this.props.langStore.products_header.boza}>
                                    <Boza />
                                </Tab>
                                <Tab eventKey={`${process.env.PUBLIC_URL}/products/water`}title={this.props.langStore.products_header.water}>
                                    <Water />
                                </Tab>
                                <Tab eventKey={`${process.env.PUBLIC_URL}/products/ayran`} title={this.props.langStore.products_header.ayran} >
                                    <Ayran />
                                </Tab>
                            </Tabs>
                        </div>
                </div>

            </div>
        )
    }
}

const mapStateToProps = function(state, ownProps) {
    return {
        langStore: state,
        location: ownProps.location
    }
}

export default connect(mapStateToProps, null)(Products);