import React from "react";
import {connect} from "react-redux";

class FAQ extends React.Component
{
    render() {
        return (
            <div className="faq">
                <h2>{this.props.langStore.faq.heading}</h2>
                <div>{this.props.langStore.faq.content}</div>
            </div>
        )
    }
}

const mapStateToProps = function(state, ownProps) {
    return {
        langStore: state,
        location: ownProps.location
    }
}

export default connect(mapStateToProps, null)(FAQ);