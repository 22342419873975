import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import 'jquery/dist/jquery.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';
import './styles/styles.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createStore } from "redux";
import { render } from 'react-dom';
import * as langTypes from './redux/constants';
import StateLoader from './redux/stateLoader';
import {langStore} from './redux/langStore'



const stateLoader = new StateLoader();

const reducer = (state, action) => {
    if (typeof state === 'undefined' || state === null) {

        return  stateLoader.initializeState();
    }

    if (action.type === langTypes.CHANGE_LANG) {
        if (action.payload.lang === langTypes.LANG_EN) {
            return langStore.en;
        } else {
            return langStore.bg;
        }
    }

    return stateLoader.loadState();
}

const store = createStore(reducer, null);

store.subscribe(() => {
    stateLoader.saveState(store.getState());
});

render(
    <Provider store={store}>
        <BrowserRouter>
            <App/>
        </BrowserRouter>
    </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();