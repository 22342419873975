import React, {Component} from "react";

import {connect} from "react-redux";
import bg_wheat from "../img/zdravec/zdravec-icons/zdravec_bot_small.png";
import Button from "react-bootstrap/Button";
import Nav from "react-bootstrap/Nav";


class Ayran extends Component {


    render() {
        return (
            <section>
                <div id="about">
                    <div className="container-fluid">
                        <div className="row pad justify-content-md-center text-green about-container">
                            <div className="col-lg-6 zdravec-ayran-content-text pad">
                                <h2 className="text-center pt-5">{this.props.langStore.ayran.heading}</h2>
                                <div className="col-md-auto">
                                    <div className="pb-3">{this.props.langStore.ayran.content.part_1}</div>

                                </div>
                            </div>
                            <div className="col-lg-6 zdravec-image-container ayran-image-first">

                            </div>
                        </div>
                    </div>

                    <div className="container revs text-center text-green">
                        <div className="texts pb-5">
                            <img src={bg_wheat} className="img-responsive w-75" alt="ayran"/>
                            <h2>{this.props.langStore.ayran.heading_1}</h2>
                            <p>
                                {this.props.langStore.ayran.content.part_3}
                            </p>
                            <p> {this.props.langStore.ayran.content.part_4}</p>
                            <p> {this.props.langStore.ayran.content.part_5}</p>
                            <p> {this.props.langStore.ayran.content.part_6}</p>
                            <p> {this.props.langStore.ayran.content.part_7}</p>
                            <Button size="lg" className='zdravec-card-button btn-color-grn'>
                                <Nav.Link href={`${process.env.PUBLIC_URL}/contact`}>{this.props.langStore.buttons.visit}</Nav.Link>
                            </Button>
                        </div>
                    </div>

                    <div className="container-fluid">
                        <div className="row pad justify-content-md-center text-green about-container">
                            <div className="col-lg-6 zdravec-image-container ayran-image-yova">

                            </div>

                            <div className="col-lg-6 zdravec-ayran-content-text pad">
                                <h2 className="text-center pt-5 pb-5">{this.props.langStore.ayran.heading_2}</h2>
                                <div className="col-md-auto">
                                    <div className="pb-5">{this.props.langStore.ayran.content.part_2}</div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
        )
    }
}

const mapStateToProps = function(state, ownProps) {
    return {
        langStore: state,
        location: ownProps.location
    }
}

export default connect(mapStateToProps, null)(Ayran);