import React, {Component} from "react";

import {connect} from "react-redux";
import zeo3 from "../img/zdravec/mineral-zeolite/Zeolite960x560-960x560.jpg";

class Zeolite extends Component {


    render() {
        return (
            <section>
                <div id="mineral-zeolite">
                    <div className="container-fluid">
                        <div className="row p-5 justify-content-md-center ">
                            <img src={zeo3} className="img-responsive w-75" alt="zeolite"/>
                        </div>
                        <div className="row p-5 justify-content-md-center text-green">
                            <div className="col-lg-6 pl-5 pr-5 pb-5">
                                <h2 className="text-center pb-2">{this.props.langStore.zeolite.heading}</h2>
                                <div className="col-md-auto zeolite-content">
                                    <div className="pb-3">{this.props.langStore.zeolite.content.part_1}</div>
                                    <div className="pb-3">{this.props.langStore.zeolite.content.part_2}</div>
                                    <div className="pb-3">{this.props.langStore.zeolite.content.part_3}</div>
                                    <div className="pb-3">{this.props.langStore.zeolite.content.part_4}</div>
                                    <div className="pb-3">{this.props.langStore.zeolite.content.part_5}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

const mapStateToProps = function(state, ownProps) {
    return {
        langStore: state,
        location: ownProps.location
    }
}

export default connect(mapStateToProps, null)(Zeolite);