import React, {Component} from "react";
import bg_wheat from "../img/zdravec/zdravec-icons/zdravec_bot_small.png"
import {connect} from "react-redux";
import Button from "react-bootstrap/Button";
import Nav from "react-bootstrap/Nav";

class Testimonials extends Component {

    render () {
        return (
            <section id="testimonials">
                <div className="container revs text-center text-light pad">
                    <div className="texts pb-5">
                        <div><img src={bg_wheat} className="img-responsive w-75" alt="bottles"/></div>

                    </div>
                    <Button size="lg" className='zdravec-card-button btn-color-grn'>
                        <Nav.Link href={`${process.env.PUBLIC_URL}/boza`}>{this.props.langStore.buttons.boza}</Nav.Link>
                    </Button>
                </div>
            </section>
        )
    }
}

const mapStateToProps = function(state, ownProps) {
    return {
        langStore: state,
        location: ownProps.location
    }
}

export default connect(mapStateToProps, null)(Testimonials);

/**
 <div className="carousel-example-generic" className="carousel-fade" data-ride="carousel"
 data-interval="5000">
 <div className="carousel-inner" role="listbox">
 <div className="carousel-item active">
 <h3> Jane Smith</h3>
 <p><em>It is a long established fact that a reader will be distracted by the readable
 content of a page when looking at its layout.</em></p>
 <p><strong>Kaufland Manager</strong></p>
 </div>
 <div className="carousel-item">
 <h3>Pablo Aymar</h3>
 <p><em>It is a long established fact that a reader will be distracted by the readable
 content of a page when looking at its layout.</em></p>
 <p><strong>Danone</strong></p>
 </div>
 <div className="carousel-item">
 <h3>Juan Veron</h3>
 <p><em>It is a long established fact that a reader will be distracted by the readable
 content of a page when looking at its layout.</em></p>
 <p><strong>Lidl</strong></p>
 </div>
 <a className="left carousel-control" href="#carousel-example-generic" role="button"
 data-slide="prev">
 <span className="icon-prev" aria-hidden="true"></span>
 <span className="sr-only">Previous</span>
 </a>
 <a className="right carousel-control" href="#carousel-example-generic" role="button"
 data-slide="next">
 <span className="icon-next" aria-hidden="true"></span>
 <span className="sr-only">Next</span>
 </a>
 </div>
 </div> */