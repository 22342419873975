import React from 'react'
import { Route, Switch } from 'react-router-dom';
import Home from './Home'
import ProductBoza from "./Boza";
import Contact from "./Contact";
import About from './About';
import Zeolite from './Zeolite';
import Water from './Water';
import Products from "./Products";
import Ayran from "./Ayran";
import FAQ from "./FAQ";

const NavigationRoutes = () => (
        <Switch>
            <Route path = {`${process.env.PUBLIC_URL}/`}  exact component = { Home } />
            <Route path = {`${process.env.PUBLIC_URL}/home`}  component = { Home } />
            <Route path = {`${process.env.PUBLIC_URL}/products`}  component = { Products } />
            <Route path = {`${process.env.PUBLIC_URL}/boza`}  component = { ProductBoza } />
            <Route path = {`${process.env.PUBLIC_URL}/about`}  component = { About } />
            <Route path = {`${process.env.PUBLIC_URL}/contact`} component = { Contact } />
            <Route path = {`${process.env.PUBLIC_URL}/zeolite`} component = { Zeolite } />
            <Route path = {`${process.env.PUBLIC_URL}/mineral-water`} component = { Water } />
            <Route path = {`${process.env.PUBLIC_URL}/water`}  component = { Water } />
            <Route path = {`${process.env.PUBLIC_URL}/ayran`} component = { Ayran } />
            <Route path = {`${process.env.PUBLIC_URL}/products/boza`} component = { Products } />
            <Route path = {`${process.env.PUBLIC_URL}/products/water`} component = { Products } />
            <Route path = {`${process.env.PUBLIC_URL}/products/ayran`}component = { Products } />
            <Route path = {`${process.env.PUBLIC_URL}/faq`}  exact component = { FAQ } />
        </Switch>
);

export default NavigationRoutes;

