import React from "react";
import {connect} from "react-redux";

const Loading = (props) => {
    return (
        <div id="loading" className="justify-content-center">
            {props.langStore.company_name}
        </div>
    );
}

const mapStateToProps = function(state, ownProps) {
    return {
        langStore: state,
        location: ownProps.location
    }
}

export default connect(mapStateToProps, null)(Loading);