import React, {useState} from 'react';
import {FaArrowCircleUp} from 'react-icons/fa';
import '../App.css';


const ScrollArrow = () =>{

    const [showScroll, setShowScroll] = useState(false)

    const checkScrollTop = () => {
        if (!showScroll && window.pageYOffset > 400){
            setShowScroll(true)
        } else if (showScroll && window.pageYOffset <= 400){
            setShowScroll(false)
        }
    };

    let smoothScrollTo = function(endX, endY, duration) {
        let startX = window.scrollX || window.pageXOffset,
            startY = window.scrollY || window.pageYOffset,
            distanceX = endX - startX,
            distanceY = endY - startY,
            startTime = new Date().getTime();

        console.log(endX, endY, duration);

        // Easing function
        let easeInOutQuart = function(time, from, distance, duration) {
            if ((time /= duration / 2) < 1) return distance / 2 * time * time * time * time + from;
            return -distance / 2 * ((time -= 2) * time * time * time - 2) + from;
        };

        let timer = window.setInterval(function() {
            let time = new Date().getTime() - startTime,
                newX = easeInOutQuart(time, startX, distanceX, duration),
                newY = easeInOutQuart(time, startY, distanceY, duration);
            if (time >= duration) {
                window.clearInterval(timer);
            }
            window.scrollTo(newX, newY);
        }, 1000 / 60); // 60 fps
    };

    const scrollTop = () =>{
        if ('scrollBehavior' in document.documentElement.style) { //Checks if browser supports scroll function
            window.scrollTo({top: 0, behavior: 'smooth'});
        } else {
            smoothScrollTo(0, 0, 500); //polyfill below
        }
    };

    window.addEventListener('scroll', checkScrollTop)

    return (
        <FaArrowCircleUp className="scrollTop" onClick={scrollTop} style={{color:"#285a5b", display: showScroll ? 'flex' : 'none'}}/>
    );
}

export default ScrollArrow;