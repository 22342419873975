import React, {Component} from "react";

import {connect} from "react-redux";
import Button from "react-bootstrap/Button";
import Nav from "react-bootstrap/Nav";
import bg_wheat from "../img/zdravec/zdravec-icons/zdravec_bot_small.png";


class Water extends Component {


    render() {
        return (
            <section>
                <div id="about" className="pad water">
                    <div className="container-fluid">
                        <div className="row justify-content-md-center text-white">
                            <div className="col-lg-6 zdravec-water-content-text pad">
                                <h2 className="text-center pt-5">{this.props.langStore.water.heading}</h2>
                                <div className="col-md-auto">
                                    <div className="pb-3">{this.props.langStore.water.content.part_1}</div>
                                    <div className="pb-3">{this.props.langStore.water.content.part_2}</div>
                                    <div className="pb-3">{this.props.langStore.water.content.part_3}</div>
                                    <div className="pb-3">{this.props.langStore.water.content.part_4}</div>
                                    <div className="pb-3">{this.props.langStore.water.content.part_5}</div>
                                </div>
                            </div>
                            <div className="col-lg-6 zdravec-image-container water-image-first">

                            </div>
                        </div>
                    </div>

                    <div className="container revs text-center text-green">
                        <div className="texts pb-5">
                            <img src={bg_wheat} className="img-responsive w-75" alt="water"/>
                            <h2>{this.props.langStore.water.drink_water.heading}</h2>
                            <div>
                                {this.props.langStore.water.drink_water.content}
                            </div>
                            <Button size="lg" className='zdravec-card-button btn-color-grn'>
                                <Nav.Link href={`${process.env.PUBLIC_URL}/contact`}>{this.props.langStore.buttons.visit}</Nav.Link>
                            </Button>
                        </div>
                    </div>

                    <div className="container-fluid">
                        <div className="row justify-content-md-center text-white about-container">
                            <div className="col-lg-6 zdravec-image-container water-image-second">
                            </div>
                            <div className="col-lg-6 zdravec-water-content-text pad">
                                <h2 className="text-center pt-5">{this.props.langStore.water.zeolite.heading}</h2>
                                <div className="col-md-auto">
                                    <div className="pb-3">{this.props.langStore.water.zeolite.content.part_1}</div>
                                </div>
                                <Button size="lg" className='zdravec-zeolite-button btn-color-white'>
                                    <Nav.Link href={`${process.env.PUBLIC_URL}/zeolite`}>{this.props.langStore.buttons.learn_more}</Nav.Link>
                                </Button>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

const mapStateToProps = function(state, ownProps) {
    return {
        langStore: state,
        location: ownProps.location
    }
}

export default connect(mapStateToProps, null)(Water);

