import React, {Component} from "react";
import bg_wheat from "../img/zdravec/zdravec-icons/zdravec_bot_small.png";
import Nav from "react-bootstrap/Nav";
import Button from "react-bootstrap/Button";
import {connect} from "react-redux";

class About extends Component {

    render () {
        return (
            <section>
                <div id="about">
                    <div className="container-fluid">
                        <div className="row justify-content-md-center text-white about-container ">
                            <div className="col-lg-6 grn pad about-container justify-content-center">
                                <h2 className="text-center mt-5">{this.props.langStore.about.the_present.heading}</h2>
                                <div className="col-md-auto">
                                    {this.props.langStore.about.the_present.content}
                                </div>
                            </div>
                            <div className="col-lg-6 zdravec-image-container about-image-second">

                            </div>
                        </div>
                    </div>

                    <div className="container revs text-center text-green">
                        <div className="texts pb-5">
                            <img src={bg_wheat} className="img-responsive w-75" alt="pleven"/>
                            <h2>{this.props.langStore.about.modernization.heading}</h2>
                            <div className=".col-md-auto">
                                {this.props.langStore.about.modernization.content}
                            </div>
                            <Button size="lg" className='zdravec-card-button btn-color-grn'>
                                <Nav.Link href={`${process.env.PUBLIC_URL}/contact`}>{this.props.langStore.buttons.visit}</Nav.Link>
                            </Button>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

const mapStateToProps = function(state, ownProps) {
    return {
        langStore: state,
        location: ownProps.location
    }
}

export default connect(mapStateToProps, null)(About);