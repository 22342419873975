import React, {Component} from "react";
import CardGroup from "react-bootstrap/CardGroup";
import Button from 'react-bootstrap/Button'
import boza1 from '../img/zdravec/used-img/home-cards/boza-flowers.png';
import all_products from '../img/zdravec/used-img/home-cards/all-products.jpg'
import Nav from "react-bootstrap/Nav";
import orange from '../img/zdravec/orange-background.png';
import {connect} from "react-redux";


class ProductCardBootstrapped extends Component {

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll(event) {
        let animation_elements = document.getElementsByClassName('animatable');

        let window_height = window.innerHeight

        let body = document.body;
        let docElem = document.documentElement;
        let window_top_position = window.pageYOffset || docElem.scrollTop || body.scrollTop;
        //let window_top_position = document.querySelector('body').scrollTo(0,0);
        let window_bottom_position = (window_top_position + window_height);

        for (let i = 0, len = animation_elements.length; i < len; i++) {
            let animation_element = animation_elements[i];

            let rect = animation_element.getBoundingClientRect();
            let element_height = rect.height;//animation_element.offsetHeight;


            let element_top_position = animation_element.offsetTop;//rect.top + document.body.scrollTop;
            let element_bottom_position = (element_top_position + element_height);

            //check to see if this current container is within viewport
            if ((element_bottom_position >= window_top_position) &&
                (element_top_position <= window_bottom_position)) {
                animation_element.classList.add('animate');
            } else {
                animation_element.classList.remove('animate');
            }
        }
    }


    render () {
        return (
            <section className='section-card home-products'>
                <CardGroup>
                    <div className="card from-bottom animatable">
                        <img className="img-fluid card-img img-up" src={boza1} alt="Deer in nature"/>
                        <div className="card-img-overlay d-flex mx-auto zdravec-card-content-box">
                            <div className="my-auto mx-auto text-center">
                                <h2 className="card-title">{this.props.langStore.cards.card_about.heading}</h2>
                                <p className="card-text"><Nav.Link href={`${process.env.PUBLIC_URL}/about`}>{this.props.langStore.cards.card_about.content}</Nav.Link></p>
                            </div>

                        </div>
                    </div>
                    <div className="card from-bottom animatable">
                        <img className="img-fluid card-img" src={orange} alt="orange-background-products"/>
                        <div className="card-img-overlay d-flex zdravec-products-colored">
                            <div className='zdravec-svg-box'>
                                <svg preserveAspectRatio="xMidYMid meet" id="comp-jwu633oxsvgcontent"
                                     data-bbox="20 20 160 160" xmlns="http://www.w3.org/2000/svg" width="100%"
                                     height="100%" viewBox="20 20 160 160" data-type="color" role="img"
                                     aria-labelledby="comp-jwu633ox-svgtitle"><title id="comp-jwu633ox-svgtitle">Best
                                    Quality Tag</title>
                                    <g>
                                        <path fill="#285a5b"
                                              d="M100 20c-44.112 0-80 35.888-80 80s35.888 80 80 80 80-35.888 80-80-35.888-80-80-80zm0 158.117c-43.074 0-78.117-35.043-78.117-78.117S56.926 21.883 100 21.883 178.117 56.926 178.117 100 143.074 178.117 100 178.117z"
                                              data-color="1"></path>
                                        <path fill="#285a5b"
                                              d="M56.462 54.953c.276-.382.38-.778.312-1.191-.068-.412-.315-.832-.744-1.257-.66-.656-1.301-.942-1.924-.86-.623.083-1.265.457-1.927 1.122l-1.726 1.736 7.031 6.989 2.019-2.031c.644-.648.958-1.308.942-1.98s-.363-1.345-1.04-2.018c-1.001-.994-1.982-1.165-2.943-.51zm-1.866 1.131l-1.979-1.967.259-.26c.408-.411.765-.64 1.071-.687.305-.047.638.108.997.464.37.368.57.702.6 1.002.03.3-.139.635-.507 1.005l-.441.443zm3.746 2.741l-.492.495-2.369-2.355.449-.451c.374-.376.736-.547 1.089-.513s.74.26 1.162.68c.411.409.634.775.67 1.097.035.321-.134.671-.509 1.047z"
                                              data-color="1"></path>
                                        <path
                                            d="M64.689 53.31l-1.984-2.627 1.484-1.122-.76-1.005-1.484 1.121-1.719-2.275 1.885-1.424-.768-1.016-3.32 2.509 5.976 7.91 3.34-2.523-.746-.987-1.904 1.439z"
                                            fill="#285a5b" data-color="1"></path>
                                        <path fill="#285a5b"
                                              d="M72.118 45.467a4.925 4.925 0 0 0-1.579-.588l-1.611-.345c-.369-.074-.661-.174-.877-.301-.216-.126-.402-.329-.558-.606-.132-.235-.177-.465-.134-.69s.177-.401.405-.529c.32-.18.602-.181.846-.005.244.177.508.494.793.952l1.224-.996c-.453-.69-.96-1.157-1.522-1.398s-1.215-.153-1.963.266c-.697.391-1.139.884-1.324 1.479-.185.595-.088 1.231.291 1.907.256.456.559.8.909 1.033.351.234.774.399 1.268.496l1.621.339c.487.101.874.228 1.161.381.287.153.526.399.718.741.14.249.192.479.156.691-.035.211-.175.385-.416.521a.872.872 0 0 1-.963-.028c-.314-.202-.636-.571-.965-1.107l-1.206 1.027c.526.855 1.107 1.395 1.743 1.619.635.223 1.344.116 2.127-.324.427-.239.753-.542.979-.907.225-.365.339-.758.341-1.18a2.515 2.515 0 0 0-.344-1.252c-.295-.527-.668-.925-1.12-1.196z"
                                              data-color="1"></path>
                                        <path
                                            d="M78.184 37.772l-.489-1.241-4.59 1.806.489 1.241L75.063 39l3.141 7.984 1.685-.663-3.141-7.984 1.436-.565z"
                                            fill="#285a5b" data-color="1"></path>
                                        <path fill="#285a5b"
                                              d="M94.38 39.782l-.516-3.684c-.146-1.043-.474-1.8-.985-2.272-.511-.473-1.272-.638-2.281-.496-1.018.143-1.708.511-2.07 1.105-.362.594-.47 1.413-.324 2.455l.515 3.685c.147 1.05.477 1.818.99 2.302.513.485 1.278.655 2.296.513.097-.014.165-.028.205-.041.303.345.662.669 1.075.974.414.305.775.505 1.085.602l.173-1.235c-.299-.131-.67-.401-1.114-.808.845-.556 1.162-1.589.951-3.1zm-1.801 1.791c-.102.274-.354.439-.758.495-.404.057-.692-.03-.865-.262-.172-.231-.292-.585-.359-1.062l-.633-4.521c-.067-.477-.048-.846.055-1.107.103-.262.357-.421.761-.478.404-.056.692.029.864.256.172.228.291.576.356 1.044l.633 4.521c.066.469.047.84-.054 1.114z"
                                              data-color="1"></path>
                                        <path fill="#285a5b"
                                              d="M102.037 39.727c-.009.571-.085 1.01-.224 1.318-.14.308-.421.458-.846.451-.416-.007-.688-.166-.818-.479-.13-.312-.19-.754-.181-1.325l.112-6.914-1.762-.029-.109 6.706c-.012.742.06 1.356.215 1.84s.435.86.838 1.128c.404.268.964.407 1.682.419.718.011 1.283-.11 1.694-.364.412-.255.704-.623.875-1.106.171-.483.263-1.091.274-1.825l.11-6.706-1.751-.029-.109 6.915z"
                                              data-color="1"></path>
                                        <path fill="#285a5b"
                                              d="M110.336 33.571l-3.609 9.443 1.677.284.781-2.189 1.701.288.005 2.322 1.653.28-.3-10.105-1.908-.323zm.548 6.66l-1.328-.225 1.359-3.991-.031 4.216z"
                                              data-color="1"></path>
                                        <path
                                            d="M118.559 35.358l-2.992 9.452 4.014 1.27.373-1.178-2.298-.728 2.618-8.273-1.715-.543z"
                                            fill="#285a5b" data-color="1"></path>
                                        <path d="M126.576 38.238l1.631.729-4.042 9.051-1.632-.728 4.043-9.052z"
                                              fill="#285a5b" data-color="1"></path>
                                        <path
                                            d="M129.896 51.099l4.364-7.386 1.327.784.679-1.149-4.247-2.508-.678 1.148 1.36.803-4.364 7.387 1.559.921z"
                                            fill="#285a5b" data-color="1"></path>
                                        <path
                                            d="M136.181 55.558l2.345-2.968 5.357-3.582-1.296-1.024-3.339 2.369 1.476-3.841-1.326-1.047-2.226 6.056-2.345 2.967 1.354 1.07z"
                                            fill="#285a5b" data-color="1"></path>
                                        <path fill="#285a5b"
                                              d="M153.075 78.366c.348.845.836 1.39 1.462 1.635.626.245 1.381.185 2.264-.178 1.305-.538 1.842-1.377 1.61-2.517.245.402.571.652.977.749.406.097.888.031 1.446-.199.861-.354 1.373-.835 1.537-1.441.165-.606.069-1.343-.289-2.211l-.932-2.263-9.166 3.778 1.091 2.647zm7.595-4.282l.14.339c.221.536.293.953.219 1.254-.075.3-.347.547-.815.739-.483.199-.868.254-1.156.165-.289-.089-.531-.374-.731-.857l-.238-.577 2.581-1.063zm-3.735 1.539l.243.588c.202.49.22.891.052 1.203-.167.312-.527.581-1.077.808-.536.221-.96.285-1.271.193-.312-.092-.568-.384-.77-.874l-.266-.645 3.089-1.273z"
                                              data-color="1"></path>
                                        <path
                                            d="M156.123 88.159l1.198-.303-.585-2.314 3.192-.806.456 1.803 1.222-.309-.456-1.803 2.765-.699.579 2.29 1.234-.312-1.02-4.034-9.611 2.429 1.026 4.058z"
                                            fill="#285a5b" data-color="1"></path>
                                        <path fill="#285a5b"
                                              d="M164.195 90.245c-.519.058-.954.203-1.306.435a3.471 3.471 0 0 0-.951.974l-.944 1.362c-.283.409-.551.716-.803.921-.252.205-.573.329-.962.372-.284.032-.517-.01-.698-.125-.181-.115-.287-.311-.318-.586a.874.874 0 0 1 .402-.876c.309-.211.774-.364 1.396-.457l-.476-1.511c-.993.152-1.716.477-2.17.975-.453.498-.631 1.193-.531 2.085.055.487.206.905.454 1.255.248.35.566.608.954.774.388.166.817.223 1.288.17.599-.067 1.112-.255 1.537-.565.426-.31.811-.718 1.157-1.225l.946-1.349c.211-.311.417-.541.619-.691.2-.15.459-.242.775-.277.268-.03.497.018.688.145.19.126.301.319.329.579.04.365-.068.625-.325.781-.257.156-.653.276-1.185.36l.44 1.515c.813-.148 1.439-.433 1.881-.856.44-.423.614-1.06.519-1.911-.089-.795-.371-1.393-.847-1.796-.475-.405-1.099-.564-1.869-.478z"
                                              data-color="1"></path>
                                        <path
                                            d="M165.917 99.071l-.036 1.579-8.577-.197-.042 1.811 8.578.196-.036 1.542 1.334.031.113-4.931-1.334-.031z"
                                            fill="#285a5b" data-color="1"></path>
                                        <path fill="#285a5b"
                                              d="M162.751 113.588l-3.598-.947c-1.025-.269-1.861-.262-2.505.025-.644.286-1.098.926-1.36 1.92a1.05 1.05 0 0 0-.04.204 6.783 6.783 0 0 0-1.314.617c-.441.263-.766.519-.975.769l1.073.636c.236-.224.628-.463 1.175-.715.186.993 1.017 1.684 2.492 2.072l3.598.947c1.018.268 1.843.257 2.477-.032.633-.289 1.078-.927 1.339-1.913.261-.994.188-1.773-.221-2.335-.41-.565-1.124-.981-2.141-1.248zm1.082 3.246c-.104.394-.293.627-.569.698-.277.071-.644.046-1.101-.074l-4.415-1.161c-.458-.12-.793-.28-1.007-.48-.213-.2-.268-.497-.165-.892.104-.394.296-.626.576-.696.279-.07.652-.043 1.118.079l4.415 1.161c.465.122.799.282 1 .479.203.195.252.491.148.886z"
                                              data-color="1"></path>
                                        <path fill="#285a5b"
                                              d="M154.732 121.328a2.056 2.056 0 0 0-1.364.335c-.404.268-.75.73-1.04 1.387-.291.656-.398 1.225-.324 1.703.074.479.301.891.678 1.237.379.345.904.666 1.576.962l6.136 2.708.706-1.602-6.326-2.791c-.523-.23-.898-.47-1.128-.718-.229-.248-.258-.566-.087-.954.168-.381.421-.57.759-.568.337.002.768.118 1.291.348l6.326 2.791.712-1.613-6.136-2.707c-.68-.3-1.273-.473-1.779-.518z"
                                              data-color="1"></path>
                                        <path fill="#285a5b"
                                              d="M149.013 129.723l1.712 1.572-.929 1.454-2.139-.901-.902 1.414 9.424 3.66 1.04-1.63-7.291-7.004-.915 1.435zm2.583 2.345l3.146 2.806-3.87-1.671.724-1.135z"
                                              data-color="1"></path>
                                        <path
                                            d="M142.107 138.936l.941.802 1.563-1.836 6.608 5.624 1.166-1.37-7.549-6.426-2.729 3.206z"
                                            fill="#285a5b" data-color="1"></path>
                                        <path d="M139.838 141.187l6.783 7.23-1.304 1.222-6.782-7.23 1.303-1.222z"
                                              fill="#285a5b" data-color="1"></path>
                                        <path
                                            d="M140.054 152.316l-5.129-6.878-1.451 1.082 5.127 6.879-1.235.921.797 1.07 3.954-2.948-.797-1.07-1.266.944z"
                                            fill="#285a5b" data-color="1"></path>
                                        <path
                                            d="M128.456 149.753l-1.51.836 1.832 3.309 1.235 6.324 1.445-.8-.896-3.994 2.974 2.844 1.478-.818-4.726-4.392-1.832-3.309z"
                                            fill="#285a5b" data-color="1"></path>
                                        <path d="M153.125 62.307a2.337 2.337 0 1 1-4.674 0 2.337 2.337 0 0 1 4.674 0z"
                                              fill="#285a5b" data-color="1"></path>
                                    </g>
                                </svg>
                            </div>
                            <div className="my-auto mx-auto text-center zdravec-products-colored-inner">
                                <h2 className="card-title">{this.props.langStore.cards.card_products.heading_part_1} <br/> {this.props.langStore.cards.card_products.heading_part_2} </h2>
                                <p className="card-text zdravec-card-products-content mx-auto">{this.props.langStore.cards.card_products.content}</p>
                                <Button size="lg" className='zdravec-card-button btn-color-grn'>
                                    <Nav.Link href={`${process.env.PUBLIC_URL}/products/boza`}>{this.props.langStore.cards.card_products.button}</Nav.Link>
                                </Button>
                            </div>
                        </div>
                    </div>

                    <div className="card from-bottom animatable">
                        <img className="img-fluid card-img" src={all_products} alt="yova-park"/>
                        <div className="card-img-overlay d-flex mx-auto zdravec-card-content-box">
                            <div className="my-auto mx-auto text-center">
                                <h2 className="card-title">{this.props.langStore.cards.card_visit_us.heading}</h2>
                                <p className="card-text"><Nav.Link href={`${process.env.PUBLIC_URL}/contact`}>{this.props.langStore.cards.card_visit_us.content}</Nav.Link></p>
                            </div>
                        </div>
                    </div>
                </CardGroup>
            </section>
        )
    }
}

const mapStateToProps = function(state, ownProps) {
    return {
        langStore: state,
        location: ownProps.location
    }
}

export default connect(mapStateToProps, null)(ProductCardBootstrapped);