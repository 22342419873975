import React, { Component } from 'react'
import Navbar from 'react-bootstrap/Navbar'
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import logo from '../logo.svg'
import {connect} from "react-redux";
import { changeLang } from '../redux/actions';
import { withRouter } from "react-router";
import * as langTypes from "../redux/constants";

class Header extends Component {
    handleChangeLang = (lang) => {
        // dispatches actions to change lang
        this.props.changeLang(lang)
    }



    render () {

        return (
            <header>
                <Navbar className="trn navbar-custom" collapseOnSelect expand="md" bg="light" variant="light">
                    <Navbar.Brand href={`${process.env.PUBLIC_URL}/home`}>
                        <img
                            src={logo}
                            width="100"
                            height="100"
                            className="d-inline-block align-top logo-header"
                            alt="React Bootstrap logo"
                        />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse>
                        <Nav defaultActiveKey={`${process.env.PUBLIC_URL}/home`} activeKey={this.props.location.pathname} className="mr-auto ml-auto text-center" id="navbar-nav-links">
                            <Nav.Link href={`${process.env.PUBLIC_URL}/home`} >{this.props.langStore.header.home}</Nav.Link>
                            <Nav.Link active={[process.env.PUBLIC_URL+'/products/boza', process.env.PUBLIC_URL+'/products/water', process.env.PUBLIC_URL+'/products/ayran'].includes(this.props.location.pathname)}   href={`${process.env.PUBLIC_URL}/products/boza`}>{this.props.langStore.header.products}</Nav.Link>
                            <Nav.Link href={`${process.env.PUBLIC_URL}/boza`}>{this.props.langStore.header.boza}</Nav.Link>
                            <Nav.Link href={`${process.env.PUBLIC_URL}/about`}>{this.props.langStore.header.about}</Nav.Link>
                            <Nav.Link href={`${process.env.PUBLIC_URL}/contact`}>{this.props.langStore.header.contact}</Nav.Link>
                        </Nav>
                        <Nav activeKey={this.props.langStore.header.lang} id="navbar-nav-lang" className="text-center">
                            <NavDropdown  title={this.props.langStore.header.lang} id="collasible-nav-dropdown" className="text-center">
                                <NavDropdown.Item  eventKey={langTypes.LANG_BG} onClick={() => this.handleChangeLang(langTypes.LANG_BG)}>BG</NavDropdown.Item>
                                <NavDropdown.Item  eventKey={langTypes.LANG_EN}onClick={() => this.handleChangeLang(langTypes.LANG_EN)}>EN</NavDropdown.Item>
                            </NavDropdown>
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
            </header>
        );
    }
}

const mapStateToProps = function(state, ownProps) {
    return {
        langStore: state,
        location: ownProps.location
    }
}

export default withRouter(connect(mapStateToProps, { changeLang })(Header));